import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";
const ClientService = {};
ClientService.getClients = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/client/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClientService.createClient = function (data) {
  return fetch({
    method: "post",
    url: `api/client/create`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClientService.getClient = function (id) {
  return fetch({
    method: "get",
    url: `api/client/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClientService.deleteClient = function (id) {
  return fetch({
    method: "delete",
    url: `api/client/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
//n'existe pas pour l'instant
ClientService.updateClient = function (id, data) {
  return fetch({
    method: "put",
    url: `api/client/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClientService.deleteClients = function () {
  return fetch({
    method: "delete",
    url: `api/companiesClient/deleteAll`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClientService.validateClient = function (id) {
  return fetch({
    method: "post",
    url: `api/client/validateAccount/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ClientService.banneClient = function (id, data) {
  return fetch({
    method: "post",
    url: `api/client/bannedAccount/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
export default ClientService;
