// professionalSlice.js

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import ProfessionalService from "services/ProfessionalService";

export const getAllProfessionals = createAsyncThunk(
  "professionals/getAllProfessionals",
  async (page = 1, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.getProfessionals(page);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const createProfessional = createAsyncThunk(
  "professional/createProfessional",
  async (data, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.addProfessional(data);
      return response;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const updateProfessional = createAsyncThunk(
  "professionals/updateProfessional",
  async ({ id, data }, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.UpdateProfessional(id, data);
      return { id, updatedJob: response.data };
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const deleteProfessional = createAsyncThunk(
  "professionals/deleteProfessional",
  async (id, { rejectWithValue }) => {
    try {
      await ProfessionalService.deleteProfessional(id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

export const fetchOneProfessional = createAsyncThunk(
  "professionals/fetchOneProfessional",
  async (id, { rejectWithValue }) => {
    try {
      const response = await ProfessionalService.getOneProfessional(id);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response || "Erreur");
    }
  }
);

const initialState = {
  professionals: [],
  professional: {},
  loading: false,
  error: null,
};

const professionalSlice = createSlice({
  name: "professionals",
  initialState,
  reducers: {
    showLoading: (state) => {
      state.loading = true;
    },
    hideLoading: (state, action) => {
      state.loading = false;
    },
    hideError: (state, action) => {
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Fetch professionals
      .addCase(getAllProfessionals.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getAllProfessionals.fulfilled, (state, action) => {
        state.loading = false;
        state.professionals = action.payload.data;
        state.totalItems = action.payload.totalItems;
      })
      .addCase(getAllProfessionals.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      // Create Professional
      .addCase(createProfessional.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createProfessional.fulfilled, (state, action) => {
        state.professionals.push(action.payload);
        state.loading = false;
        state.error = null;
      })
      .addCase(createProfessional.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      // Update professional
      .addCase(updateProfessional.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateProfessional.fulfilled, (state, action) => {
        const { professionalId, updatedJob } = action.payload;
        const index = state.professionals.findIndex(
          (professional) => professional.id === professionalId
        );
        if (index !== -1) {
          state.professionals[index] = {
            ...state.professionals[index],
            ...updatedJob,
          };
        }
        state.loading = false;
      })
      .addCase(updateProfessional.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      // Delete professional
      .addCase(deleteProfessional.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(deleteProfessional.fulfilled, (state, action) => {
        const professionalId = action.payload;
        state.professionals = state.professionals.filter(
          (professional) => professional._id !== professionalId
        );
        state.loading = false;
        state.error = null;
      })
      .addCase(deleteProfessional.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      .addCase(fetchOneProfessional.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(fetchOneProfessional.fulfilled, (state, action) => {
        state.loading = false;
        state.professional = action.payload;
        state.error = null;
      })
      .addCase(fetchOneProfessional.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});
export default professionalSlice.reducer;
export const { showLoading, hideLoading, hideError } =
  professionalSlice.actions;
