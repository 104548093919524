import fetch from "auth/FetchInterceptor";
import { AUTH_TOKEN } from "constants/AuthConstant";

const ProfessionalService = {};
ProfessionalService.addProfessional = function (data) {
  return fetch({
    method: "post",
    url: "api/companies/create",
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

ProfessionalService.UpdateProfessional = function (id, data) {
  return fetch({
    method: "put",
    url: `api/companies/update/${id}`,
    data: data,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

ProfessionalService.getProfessionals = function (page = 1) {
  return fetch({
    method: "get",
    url: `api/companies/getAll?page=${page}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

ProfessionalService.getOneProfessional = function (id) {
  return fetch({
    method: "get",
    url: `api/companies/getById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};
ProfessionalService.deleteProfessional = function (id) {
  return fetch({
    method: "delete",
    url: `api/companies/deleteById/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem(AUTH_TOKEN)}`,
    },
  });
};

export default ProfessionalService;
